import * as Updates from 'expo-updates'
import * as pkgInfo from '../../../../package.json'

const AppConfig = {
  upstream: 'https://main.k8s.culcha.cloud/',
  logLevel: 'ERROR',
  sentryDsn: 'https://54dfd6c31a964c568dec81dbd7e0f850@o515669.ingest.sentry.io/5662820',
  environment: 'production',
  version: pkgInfo.version,
  overrideFontSizeMultiplier: 1.2,
  logTime: false,
  traduoro: {
    clientId: '68170005-39ff-4a9f-99f4-b9b420230cd3',
    secret: 'iifKeak6FqaE2Ri7tWe2HaQg2mCz9F3W',
    baseUrl: 'https://translate.develop.k8s.culcha.cloud/api/v1',
    project: '2a4dc41f-f48f-43d2-bfeb-74b2c34eb0d7',
  },
  moduleThreshold: 80,
  appURL: 'https://app.culcha.com/',
  quickWinSnoozeDays: 14,
  quickWinSnoozeMins: 20,
  stripeURL: 'https://billing.stripe.com/p/login/9AQ2bM8ol0K095maEE',
  welcomeProgramKey: 'welcome-to-culcha-smb-admin',
} as IAppConfig

let { releaseChannel } = Updates

if (releaseChannel === 'default' && typeof __CULCHA_ENV__ !== 'undefined') {
  // production web app
  releaseChannel = __CULCHA_ENV__
} else if (releaseChannel === 'default' && typeof process?.env?.CULCHA_ENV !== 'undefined') {
  // local development override
  releaseChannel = process.env.CULCHA_ENV
}
if (releaseChannel !== 'production') {
  // AppConfig.upstream = 'https://develop.develop.k8s.culcha.cloud/'
  AppConfig.logLevel = 'DEBUG'
  AppConfig.logTime = true
  AppConfig.environment = Updates.releaseChannel || 'development'
  AppConfig.moduleThreshold = 70
  AppConfig.appURL = 'https://staging-app.culcha.com/'
  AppConfig.quickWinSnoozeDays = 14
  AppConfig.quickWinSnoozeMins = 1
  AppConfig.stripeURL = 'https://billing.stripe.com/p/login/test_9AQ7wo4OD7FF3cs000'
  AppConfig.welcomeProgramKey = 'welcome-to-culcha-smb-admin'
}

export default AppConfig
