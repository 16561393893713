import Configuration, { KEYS } from '@js/util/Configuration'
import { Action } from '@state/Action'
import Dispatcher from '@state/Dispatcher'
import StateChange from '@state/StateChange'
// eslint-disable-next-line import/no-cycle
import { Languages } from '@ts/constants/Languages'
import AppConfig from '@ts/util/AppConfig'
import Logger from '@ts/util/logger/Logger'
import axios, { AxiosInstance, AxiosResponse } from 'axios'

let instance: I18n | null = null
const log = new Logger('i18n')

type Translation = {
  translations: TraduoraTranslation[]
  language: string
}

class I18n {
  configuration?: Configuration

  language: 'de' | 'en' = 'de'

  client?: AxiosInstance

  authenticated = false

  translations: Map<string, Record<string, string | undefined>> = new Map()

  constructor() {
    if (!instance) {
      instance = this
    }
    return instance
  }

  async init() {
    // Do we need to create a new Configuration here?
    this.configuration = new Configuration()
    this.language = this.configuration.get(KEYS.LANGUAGE)

    this.client = axios.create({ baseURL: AppConfig.traduoro.baseUrl })
    const storedToken = this.configuration.get(KEYS.TRADUORA_TOKEN)
    const storedTokenExpires = this.configuration.get(KEYS.TRADUORA_TOKEN_EXPIRES)
    if (storedToken && storedTokenExpires && storedTokenExpires > new Date().getTime()) {
      this.setAccessToken(storedToken)
    } else {
      try {
        const response = await this.client.post<TraduoraAuthResponse>('/auth/token', {
          grant_type: 'client_credentials',
          client_id: AppConfig.traduoro.clientId,
          client_secret: AppConfig.traduoro.secret,
        })

        this.handleLogin(response)
      } catch (e) {
        log.error('failed to grab translations', e)
        this.authenticated = false
      }
    }
  }

  handleLogin(r: AxiosResponse<TraduoraAuthResponse>) {
    // eslint-disable-next-line camelcase
    const { expires_in, access_token } = r.data
    const expiresInSeconds = parseInt(expires_in.substring(0, expires_in.length - 1), 10) - 300 // subtract 5 minutes to be safe
    const expires = new Date().getTime() + expiresInSeconds * 1000

    this.configuration?.set(KEYS.TRADUORA_TOKEN, access_token)
    this.configuration?.set(KEYS.TRADUORA_TOKEN_EXPIRES, expires)

    this.setAccessToken(access_token)
  }

  setAccessToken(accessToken: string) {
    this.client!.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    this.authenticated = true
  }

  get(key: string, data?: DataType, language?: string) {
    if (this.translations.size === 0) this.setCurrentLanguageLegacy()
    const msg = this.translations.get(language || this.language)![key]
    if (msg === undefined) {
      log.debug(`missing translation for key ${key}`)
      return key
    }
    if (data) {
      return render(msg, data)
    }
    return msg
  }

  getCurrentLanguage() {
    return this.language
  }

  async setCurrentLanguage(lang: 'de' | 'en') {
    if (lang === this.language && Object.keys(this.translations).length > 0) {
      return
    }
    this.language = lang
    if (!this.authenticated) {
      log.warn('i18n is not authenticated, skipping grabbing fresh translations')
      this.setCurrentLanguageLegacy()
      Dispatcher.dispatchStateChange(new StateChange(Action.SET_LANGUAGE, lang))
      return
    }
    try {
      const termsResponse = await this.client!.get<TraduoraTermsResponse>(
        `/projects/${AppConfig.traduoro.project}/terms`
      )
      const terms = termsResponse.data.data
      const allTranslations = await this.getTranslations()
      this.setCurrentLanguageLegacy()
      Languages.forEach(l => {
        const map: Record<string, string | undefined> = {}
        const languageTranslation = allTranslations.find(t => t.language === l.value)
        if (languageTranslation?.translations) {
          terms.forEach(term => {
            const translation = languageTranslation.translations.find(i => i.termId === term.id)
            map[term.value] = translation?.value
          })
        }
        this.translations.set(l.value, { ...this.translations.get(l.value), ...map })
        log.info(`Successfully used remote translations for ${l.value}`)
      })
    } catch (e) {
      log.warn('Could not retrieve translations', e)
    } finally {
      Dispatcher.dispatchStateChange(new StateChange(Action.SET_LANGUAGE, lang))
      this.configuration?.set(KEYS.LANGUAGE, lang)
    }
  }

  setCurrentLanguageLegacy() {
    log.info('Falling back to flat file translations. Which might be outdated!')
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    this.translations.set('en', require('../../resources/i18n/en.json'))
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    this.translations.set('de', require('../../resources/i18n/de.json'))
  }

  async getTranslations(): Promise<Translation[]> {
    const translations: Translation[] = []
    await Promise.all(
      Languages.map(async lang => {
        const traduoraTranslationsResponse = await this.client!.get<TraduoraTranslationsResponse>(
          `/projects/${AppConfig.traduoro.project}/translations/${lang.value}`
        )
        translations.push({
          language: lang.value,
          translations: traduoraTranslationsResponse.data.data,
        })
      })
    )

    return translations
  }
}

const i18n = new I18n()

export const t = (key: string, data?: DataType, language?: string): string => i18n.get(key, data, language)
export const getDate = (date: number | Date | undefined): string => new Intl.DateTimeFormat(t('locale')).format(date)
export default i18n

type TraduoraTermsResponse = {
  data: TraduoraTerm[]
}

type TraduoraTerm = {
  id: string
  value: string
  labels: string[]
  date: {
    created: string
    modified: string
  }
}

type TraduoraTranslationsResponse = {
  data: TraduoraTranslation[]
}

type TraduoraTranslation = {
  termId: string
  value: string
  labels: string[]
  date: {
    created: string
    modified: string
  }
}

type TraduoraAuthResponse = {
  // eslint-disable-next-line camelcase
  access_token: string
  // eslint-disable-next-line camelcase
  expires_in: string
  // eslint-disable-next-line camelcase
  token_type: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataType = Record<string, any>

// this is the render function from es6-template-render, we are not using that library directly as it is not typescript and it is a big security risk to use it blindly
function render(string: string, context: DataType, stack?: string): string {
  return Object.keys(context).reduce((accumulator, key) => {
    const newStack = stack ? `${stack}.` : ''
    const find = `\\$\\{\\s*${newStack}${key}\\s*\\}`
    const re = new RegExp(find, 'g')

    if (typeof context[key] === 'object') {
      return render(accumulator, context[key], newStack + key)
    }
    return accumulator.replace(re, context[key])
  }, string)
}
